import { useQuery } from "@apollo/client";
import {
    GET_PRODUCT,
    PageTitle,
    ProductQueryData,
    showLoad,
} from "@plinknz/tah-website-elements";
import * as React from "react";
import { useParams } from "react-router-dom";
import { Product } from "../components/content-blocks/product";
import { Hero } from "../components/hero";
import { Loader } from "../components/loader";
import { NotFound } from "./404";

export const ProductPage = () => {
    const { id } = useParams<{ id: string }>();
    const { loading, data, error } = useQuery<ProductQueryData>(GET_PRODUCT, {
        variables: { id },
    });

    if (!data && showLoad(loading)) {
        return (
            <div className="splash">
                <Loader size="large" />
            </div>
        );
    }

    if (!showLoad(loading) && !error && !data?.product) {
        return <NotFound />;
    }

    if (error || !data) {
        console.error(error);
        const errorMessage = error?.graphQLErrors
            .map((graphqlError) =>
                graphqlError.extensions?.exception
                    ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
                      `${graphqlError.extensions.exception.output.statusCode} ${graphqlError.extensions.exception.message}`
                    : null
            )
            .filter(Boolean)
            .join(", ");

        return (
            <Hero
                title="There was an unexpected error"
                subtitle={errorMessage}
            />
        );
    }

    const { product } = data;

    return (
        <>
            <PageTitle title={product.title} />
            <Hero title={product.title} />
            <article className="product-page">
                <Product
                    data={{
                        product,
                    }}
                />
            </article>
        </>
    );
};
