/* eslint-disable jsx-a11y/anchor-is-valid,max-lines */
/* cSpell:disable */
import { paramCase } from "change-case";
import * as React from "react";
import { Helmet } from "react-helmet";
import { classNames, ProductImage } from "@plinknz/tah-website-elements";
import { BlockQuote } from "../components/blockquote";
import { Button } from "../components/button";
import { CallToActionBlock } from "../components/content-blocks/call-to-action";
import { DownloadBlock } from "../components/content-blocks/download-block";
import { GalleryBlock } from "../components/content-blocks/gallery";
import { LinksBlock } from "../components/content-blocks/links-block";
import { NewsGrid } from "../components/content-blocks/news-grid";
import { PictureList } from "../components/content-blocks/picture-list";
import { YoutubePlayerContentBlock } from "../components/content-blocks/youtube-player";
import { Form } from "../components/form/form";
import { Hero } from "../components/hero";
import { Icon } from "../components/icon";
import { Loader } from "../components/loader";
import { Product } from "../components/content-blocks/product";

interface StyleGuideSectionProps extends React.HTMLAttributes<HTMLDivElement> {
    title: string;
    subtitle?: string;
    contain?: boolean;
}
const StyleGuideSection: React.FunctionComponent<StyleGuideSectionProps> = ({
    title,
    subtitle,
    children,
    contain = false,
    className,
    ...props
}: StyleGuideSectionProps) => (
    <section
        id={paramCase(title)}
        className={classNames(
            "style-guide-section",
            { "constrain-width": contain },
            className
        )}
        {...props}>
        <div
            className={`style-guide-section-heading ${
                !contain ? "constrain-width" : ""
            }`}>
            <h1 className="heading-2">
                {title}{" "}
                {subtitle && (
                    <small className="style-guide-section-subtitle">
                        | {subtitle}
                    </small>
                )}
            </h1>
            <hr className="style-guide-section-break" />
        </div>
        <div className="style-guide-section-content">{children}</div>
    </section>
);

interface SwatchProps {
    title: string;
    value: string;
    outline?: boolean;
}
const Swatch: React.FunctionComponent<SwatchProps> = ({
    title,
    value,
    outline = false,
}: SwatchProps) => (
    <div className={`swatch small ${outline ? "outline" : ""} colour-${value}`}>
        <div className="swatch-inner">
            <p className="swatch-title">{title}</p>
            <div role="group" className="swatch-variations">
                <span className="swatch-variation tint-1" />
                <span className="swatch-variation shade-1" />
            </div>
        </div>
    </div>
);

export const StyleGuide = () => (
    <div className="style-guide">
        <Helmet>
            <title>Style Guide - Ngāti Rangi</title>
        </Helmet>

        <Hero
            title="Ārahi Huatau / Style Guide"
            subtitle="A working site style guide built to manage design and code quality control."
        />

        <StyleGuideSection title="Tae" subtitle="Colours" contain>
            <div className="style-guide-swatches" role="group">
                <Swatch title="Primary" value="primary" />
                <Swatch title="Secondary" value="secondary" outline />
                <Swatch title="Tertiary" value="tertiary" />
                <Swatch title="Dark" value="dark" />
                <Swatch title="Light" value="light" outline />
            </div>
        </StyleGuideSection>

        <StyleGuideSection title="Momotuhi" subtitle="Type" contain>
            <h1>Header 1</h1>
            <h2>Header 2</h2>
            <h3>Header 3</h3>
            <h4>Header 4</h4>
            <h5>Header 5</h5>
            <h6>Header 6</h6>

            <p>
                Whaia e au Manganui-o-te-ao kia tau au ki runga o Ruapehu ki Nga
                Turi o Murimotu ko te Ahi-ka o Paerangi-i-te-Whare-Toka i puta
                mai ai a Rangituhia, Rangiteauria me Uenuku-Manawa-Wiri.
            </p>
            <p>
                Nau mai ra e te hunga titiro ki te whārangi ipurangi Ngāti
                Rangi! E tuku atu ana i nga mihi o te Nuku, o te Rangi kia
                koutou, tātau ngā uri o Ngāti Rangi e noho ana i nga
                papa-tairite, puta atu i te ao.
            </p>
            <p>
                Lorem <em>ipsum</em> dolor sit amet consectetur adipisicing
                elit. Magni dicta provident aliquam aut, laboriosam maxime,
                autem nemo voluptate iusto voluptas odio facilis perferendis
                quod a<strong>reprehenderit tempora</strong> accusantium commodi
                eveniet?
            </p>
        </StyleGuideSection>

        <StyleGuideSection title="Rārangi" subtitle="Lists" contain>
            <h4>Unordered</h4>
            <ul>
                <li>
                    Duis autem vel eum{" "}
                    <strong>iriure dolor in hendrerit</strong> in vulputate
                    velit esse molestie consequat.
                </li>
                <li>
                    Vel illum dolore eu <em>feugiat nulla</em> facilisis at vero
                    eros et accumsan et iusto odio dignissim qui blandit.
                </li>
                <li>
                    Praesent luptatum zzril delenit augue duis dolore te feugait
                    nulla facilisi.
                </li>
                <li>
                    Nimperdiet doming id quod mazim placerat facer possim assum.
                    Typi non habent claritatem insitam; est usus{" "}
                    <a href="#">legentis in iis qui facit</a> eorum claritatem.
                    Investigationes demonstraverunt lectores legere me lius quod
                    ii legunt saepius.
                </li>
            </ul>

            <h4>Ordered</h4>
            <ol>
                <li>
                    Duis autem vel eum{" "}
                    <strong>iriure dolor in hendrerit</strong> in vulputate
                    velit esse molestie consequat.
                </li>
                <li>
                    Vel illum dolore eu <em>feugiat nulla</em> facilisis at vero
                    eros et accumsan et iusto odio dignissim qui blandit.
                </li>
                <li>
                    Praesent luptatum zzril delenit augue duis dolore te feugait
                    nulla facilisi.
                </li>
                <li>
                    Nimperdiet doming id quod mazim placerat facer possim assum.
                    Typi non habent claritatem insitam; est usus{" "}
                    <a href="#">legentis in iis qui facit</a> eorum claritatem.
                    Investigationes demonstraverunt lectores legere me lius quod
                    ii legunt saepius.
                </li>
            </ol>
        </StyleGuideSection>

        <StyleGuideSection
            title="Pātene"
            subtitle="Buttons"
            contain
            className="style-guide-buttons">
            <Button sizeStyle="large">
                Button <Button.Icon className="fas fa-chevron-right" />
            </Button>
            <Button>Button</Button>
            <Button sizeStyle="small">Button</Button>
            <br />
            <br />

            <Button variant="primary" sizeStyle="large">
                Primary Button
            </Button>
            <Button variant="primary">
                Primary Button <Button.Icon className="fas fa-chevron-right" />
            </Button>
            <Button variant="primary">
                <Button.Icon className="fas fa-chevron-left" />
                Primary Button
            </Button>
            <Button variant="primary" sizeStyle="small">
                Primary Button
            </Button>
            <br />
            <br />

            <Button variant="secondary" sizeStyle="large">
                Secondary Button
            </Button>
            <Button variant="secondary">Secondary Button</Button>
            <Button variant="secondary" sizeStyle="small">
                Secondary Button <i className="fas fa-chevron-right" />
            </Button>
            <br />
            <br />

            <Button variant="tertiary" sizeStyle="large">
                Tertiary Button <Button.Icon className="fas fa-chevron-right" />
            </Button>
            <Button variant="tertiary">Tertiary Button</Button>
            <Button variant="tertiary" sizeStyle="small">
                Tertiary Button
            </Button>

            <br />
            <br />
            <Button disabled>Disabled Button</Button>
            <Button disabled>Disabled Button</Button>
        </StyleGuideSection>

        <StyleGuideSection title="Tohutoro" subtitle="Blockquote" contain>
            <BlockQuote
                text="Whaia e au Manganui-o-te-ao kia tau au ki runga o Ruapehu ki Nga Turi o Murimotu ko te Ahi-ka o Paerangi-i-te-Whare-Toka i puta mai ai a Rangituhia, Rangiteauria me Uenuku-Manawa-Wiri."
                citation="Ngāti Rangi"
            />
        </StyleGuideSection>

        <StyleGuideSection title="Ata" subtitle="Icons" contain>
            {/* istanbul-ignore next */}
            {require?.context &&
                require
                    .context("../../../public/icons/", true, /.svg/)
                    .keys()
                    .filter((fileName) => !fileName.includes("_icon.svg"))
                    .map((fileName) =>
                        fileName.replace("./", "").replace(".svg", "")
                    )
                    .map((iconFile) => (
                        <div key={iconFile} style={{ display: "inline-block" }}>
                            <p style={{ marginBottom: "5px" }}>
                                <small>{iconFile}</small>
                            </p>
                            <Icon
                                className="icon"
                                title={iconFile}
                                name={iconFile}
                                style={{
                                    width: "150px",
                                    height: "150px",
                                    marginRight: "10px",
                                    marginBottom: "10px",
                                }}
                            />
                        </div>
                    ))}
        </StyleGuideSection>

        <StyleGuideSection title="Whakauta" subtitle="Loading" contain>
            <div className="style-guide-loaders">
                <div>
                    <Loader size="large" />
                    <p>Large</p>
                </div>
                <div>
                    <Loader />
                    <p>Normal</p>
                </div>
                <div>
                    <Loader size="small" />
                    <p>Small</p>
                </div>
            </div>
        </StyleGuideSection>

        <StyleGuideSection
            title="Kawepūrongo"
            subtitle="News Grid"
            contain={false}>
            <NewsGrid
                data={{
                    __typename: "ComponentContentBlocksNewsGrid",
                    id: "1",
                    title: "News and Events",
                    pages: [
                        {
                            title: "Te Kāhui o Paerangi hui and wānanga",
                            slug: "/",
                            cover_image: {
                                url: "/images/13-Ngati Rangi Third Reading_DSC2113.jpg",
                            },
                        },
                        {
                            title: "Te Kāhui o Paerangi hui and wānanga",
                            slug: "/",
                            cover_image: {
                                url: "/images/5-Planting_DSC7510.jpg",
                            },
                        },
                        {
                            title: "Te Kāhui o Paerangi hui and wānanga",
                            slug: "/",
                            cover_image: {
                                url: "/images/7-Rangatahi Summit 2018_DSC8735.jpg",
                            },
                        },
                        {
                            title: "Te Kāhui o Paerangi hui and wānanga",
                            slug: "/",
                            cover_image: {
                                url: "/images/34-Te Moko Ahurea_DSC9507.jpg",
                            },
                        },
                        {
                            title: "Te Kāhui o Paerangi hui and wānanga",
                            slug: "/",
                            cover_image: {
                                url: "/images/20-Photo 25-07-19, 1 47 44 PM.jpg",
                            },
                        },
                        {
                            title: "Te Kāhui o Paerangi hui and wānanga",
                            slug: "/",
                            cover_image: {
                                url: "/images/27-Ngati Rangi_DSC5345.jpg",
                            },
                        },
                    ],
                }}
            />
        </StyleGuideSection>

        <StyleGuideSection title="Rārangi pikitia" subtitle="Picture list">
            <PictureList
                data={{
                    __typename: "ComponentContentBlocksProfileSet",
                    id: "1",
                    profileHeading: "Our board",
                    profiles: [
                        {
                            id: "a",
                            title: "Jeremy Banks",
                            subtitle: "CEO",
                            description:
                                "Jeremy has over 20 years of experience creating software. ",
                            picture: {
                                id: "2",
                                url: "/images/20-Photo 25-07-19, 1 47 44 PM.jpg",
                            },
                        },
                        {
                            id: "b",
                            title: "Melissa Banks",
                            subtitle: "Creative Director",
                            description:
                                "Liss has been involved with Plink Software since inception and is responsible for how our beautiful software looks and feels.",
                            picture: {
                                id: "3",
                                url: "/images/34-Te Moko Ahurea_DSC9507.jpg",
                            },
                        },
                        {
                            id: "c",
                            title: "Josh Post",
                            subtitle: "Senior Software Developer",
                            description: `Josh is our developer and takes care of all the technical stuff with Jeremy.

Formerly working in the media and consulting industries, he has been working professionally as a software developer since 2013.

Josh is from Balclutha, but has lived in Dunedin, Wellington and Auckland before coming to sunny Nelson.`,
                            picture: {
                                id: "4",
                                url: "/images/13-Ngati Rangi Third Reading_DSC2113.jpg",
                            },
                        },
                        {
                            id: "d",
                            title: "Melissa Waldren",
                            subtitle: "Account Manager",
                            description:
                                "Tēnā koutou e te iwi. Ko Melissa Waldren tōku ingoa. Ko Ngāti Porou me Ngāti Koroki Kahukura me Ngāti Pākehā ōku iwi.",
                            picture: {
                                id: "5",
                                url: "/images/5-Planting_DSC7510.jpg",
                            },
                        },
                    ],
                }}
            />
        </StyleGuideSection>

        <StyleGuideSection title="Tikiake" subtitle="Download">
            <DownloadBlock
                data={{
                    __typename: "ComponentContentBlocksDownloadFiles",
                    id: "1",
                    heading: "Annual Reports",
                    description:
                        "Ko te moemoea a Maui kia haere ngatahi ai ratou ko ona tuakana ki te hii ika. I te hokinga mai o ona tuakana ki tatahi, ka kii atu a Maui, “ka taea e au te haramai i to koutou na taha ki te hii ika?” Engari, ko te whakautu o ona tuakana ki a ia ano, “Kao, he rangatahi noa iho koe. Kaore he wahi mau kei te waka nei, na reira me noho tau ki tatahi ke”.",
                    files: [
                        {
                            id: "1",
                            name: "Annual Report 2020",
                            file: {
                                id: "1",
                                name: "Ngā Waihua o Paerangi Trust Registration Form",
                                url: "https://ngatirangi.com/assets/Ng%C4%81%20Waihua%20o%20Paerangi%20Trust_registration%20Form_web.pdf",
                                size: 2680900.21,
                            },
                        },
                        {
                            id: "2",
                            file: {
                                id: "1",
                                name: "Ngā Waihua o Paerangi Trust Registration Form",
                                url: "https://ngatirangi.com/assets/Ng%C4%81%20Waihua%20o%20Paerangi%20Trust_registration%20Form_web.pdf",
                                size: 13809.66,
                            },
                        },
                        {
                            id: "3",
                            name: "Ngāti Rangi Strategic Plan 2020 - 2025 Summary",
                            file: {
                                id: "1",
                                name: "Ngā Waihua o Paerangi Trust Registration Form",
                                url: "https://ngatirangi.com/assets/Ng%C4%81%20Waihua%20o%20Paerangi%20Trust_registration%20Form_web.pdf",
                                size: 546.08,
                            },
                        },
                    ],
                }}
            />
        </StyleGuideSection>

        <StyleGuideSection title="Hononga" subtitle="Links">
            <LinksBlock
                data={{
                    __typename: "ComponentContentBlocksLinks",
                    id: "31315",
                    title: "Ō Mātou Pakihi",
                    description:
                        "Ko te moemoea a Maui kia haere ngatahi ai ratou ko ona tuakana ki te hii ika. I te hokinga mai o ona tuakana ki tatahi, ka kii atu a Maui, “ka taea e au te haramai i to koutou na taha ki te hii ika?” Engari, ko te whakautu o ona tuakana ki a ia ano, “Kao, he rangatahi noa iho koe. Kaore he wahi mau kei te waka nei, na reira me noho tau ki tatahi ke”.",
                    links: [
                        {
                            title: "Ruapehu Recruitment",
                            url: "https://www.facebook.com/ruapehurecruitment/",
                        },
                        {
                            url: "https://www.ruapehuhub.com/",
                        },
                        {
                            url: "https://www.ruapehuwhanautransformation.com/",
                        },
                    ],
                }}
            />
        </StyleGuideSection>

        <StyleGuideSection
            title="Kete Whakaahua"
            subtitle="Gallery"
            contain={false}>
            <GalleryBlock
                data={{
                    __typename: "ComponentContentBlocksImageGallery",
                    id: "1",
                    heading: "Kotahi",
                    galleryId: "123456",
                    description:
                        "Ko te moemoea a Maui kia haere ngatahi ai ratou ko ona tuakana ki te hii ika. I te hokinga mai o ona tuakana ki tatahi, ka kii atu a Maui, “ka taea e au te haramai i to koutou na taha ki te hii ika?” Engari, ko te whakautu o ona tuakana ki a ia ano, “Kao, he rangatahi noa iho koe. Kaore he wahi mau kei te waka nei, na reira me noho tau ki tatahi ke”.",
                    images: [
                        {
                            heading: "Rua",
                            description:
                                "I te hokinga mai o ona tuakana ki tatahi, ka kii atu a Maui, “ka taea e au te haramai i to koutou na taha ki te hii ika?”",
                            image: {
                                alternativeText: "",
                                url: "/images/20-Photo 25-07-19, 1 47 44 PM.jpg",
                                height: 800,
                                width: 800,
                            },
                        },
                        {
                            heading: "Tahi",
                            description:
                                "Ko te moemoea a Maui kia haere ngatahi ai ratou ko ona tuakana ki te hii ika.",
                            image: {
                                alternativeText: "",
                                url: "/images/8-Rangatahi Summit 2018_DSC9662.jpg",
                                height: 800,
                                width: 800,
                            },
                        },
                        {
                            heading: "Toru",
                            description:
                                "Kaore he wahi mau kei te waka nei, na reira me noho tau ki tatahi ke",
                            image: {
                                alternativeText: "",
                                url: "/images/34-Te Moko Ahurea_DSC9507.jpg",
                                height: 800,
                                width: 800,
                            },
                        },
                    ],
                }}
            />

            <GalleryBlock
                data={{
                    __typename: "ComponentContentBlocksImageGallery",
                    id: "1",
                    heading: "Maha",
                    galleryId: "23456",
                    description:
                        "Ko te moemoea a Maui kia haere ngatahi ai ratou ko ona tuakana ki te hii ika. I te hokinga mai o ona tuakana ki tatahi, ka kii atu a Maui, “ka taea e au te haramai i to koutou na taha ki te hii ika?” Engari, ko te whakautu o ona tuakana ki a ia ano, “Kao, he rangatahi noa iho koe. Kaore he wahi mau kei te waka nei, na reira me noho tau ki tatahi ke”.",
                    images: [
                        {
                            heading: "Tahi",
                            description:
                                "Ko te moemoea a Maui kia haere ngatahi ai ratou ko ona tuakana ki te hii ika.",
                            image: {
                                alternativeText: "",
                                url: "/images/13-Ngati Rangi Third Reading_DSC2113.jpg",
                                height: 800,
                                width: 800,
                            },
                        },
                    ],
                }}
            />
        </StyleGuideSection>

        <StyleGuideSection title="Whakahauhau" subtitle="Call To Action">
            <CallToActionBlock
                data={{
                    __typename: "ComponentContentBlocksCallToAction",
                    id: "1",
                    callToActionTitle: "Register",
                    description: "Complete your registration with Ngāti Rangi",
                    action: {
                        label: "Register",
                        link: { slug: "/register" },
                    },
                    background: {
                        url: "/images/27-Ngati Rangi_DSC5345.jpg",
                    },
                }}
            />
        </StyleGuideSection>

        <StyleGuideSection title="Tiriata" subtitle="YouTube">
            <YoutubePlayerContentBlock
                data={{
                    __typename: "ComponentContentBlocksYoutubePlayer",
                    id: "1",
                    title: "Video title",
                    description:
                        "Ko te moemoea a Maui kia haere ngatahi ai ratou ko ona tuakana ki te hii ika. I te hokinga mai o ona tuakana ki tatahi, ka kii atu a Maui, “ka taea e au te haramai i to koutou na taha ki te hii ika?” Engari, ko te whakautu o ona tuakana ki a ia ano, “Kao, he rangatahi noa iho koe. Kaore he wahi mau kei te waka nei, na reira me noho tau ki tatahi ke”.",
                    youtube_url: "https://www.youtube.com/watch?v=ja8SjN2tFUI",
                }}
            />
        </StyleGuideSection>

        <StyleGuideSection title="Puka" subtitle="Forms" contain>
            <Form className="constrain-width">
                <Form.Row>
                    <Form.InputGroup name="text" label="Example">
                        <input className="input" type="text" name="text" />
                    </Form.InputGroup>
                    <Form.InputGroup name="select" label="Example">
                        <select className="dropdown" name="select">
                            <option value="1">Option 1</option>
                            <option value="2">Option 2</option>
                            <option value="3">Option 3</option>
                        </select>
                    </Form.InputGroup>
                </Form.Row>
                <Form.Actions>
                    <Button variant="primary" submit>
                        Submit
                    </Button>
                    <Button variant="tertiary">Cancel</Button>
                </Form.Actions>
            </Form>
        </StyleGuideSection>

        <StyleGuideSection title="Hoko" subtitle="Product" contain>
            <Product
                host="ngatirangi.com"
                data={{
                    product: {
                        id: "1",
                        title: "Blue T-Shirt",
                        description: `Lorem ipsum dolor sit amet consectetur adipisicing
                                elit. Labore impedit accusantium vero ipsam, sed
                                neque velit, ullam ad culpa praesentium porro
                                tempore quisquam nulla facere sunt ut iure
                                distinctio expedita.`,
                        price: 39.99,
                        variations_1_title: "Size",
                        variations_1: "Large | Medium | Small",
                        variations_2_title: "",
                        variations_2: "",
                        variations_3_title: "",
                        variations_3: "",
                        max_quantity: 3,
                        images: [
                            {
                                url: "/images/SAMPLE_TEE_MAIN.jpg",
                            } as unknown as ProductImage,
                            {
                                url: "/images/SAMPLE_TEE_TURN.jpg",
                            } as unknown as ProductImage,
                            {
                                url: "/images/SAMPLE_TEE_SIDE.jpg",
                            } as unknown as ProductImage,
                            {
                                url: "/images/SAMPLE_TEE_BACK.jpg",
                            } as unknown as ProductImage,
                        ],
                    },
                }}
            />
        </StyleGuideSection>
    </div>
);
